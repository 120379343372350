import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { Chart } from './chart/chart.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Assessments provide the opportunity to receive valuable feedback and understand the areas of strength and areas to focus future learning.`}</p>
    </blockquote>
    <p>{`Students at PSI are involved in a variety of assessments, as outlined in `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1YsUIZYDmwuKptq6Ch6tXc7av1NIwDW55X83P9if4Tww/edit?usp=sharing"
      }}>{`this document`}</a>{`.  In this report, we’ll discuss students' progress on the Measure of Academic Progress (MAP), Developmental Reading Assessment Version 2 (DRA2), Junior Assessment of Mathematics (JAM) and Global Strategy Stage (GloSS).`}</p>
    <p>{`Students in Grades 3 through 9 participated in the fall and spring in the MAP assessment.  This is an adaptive assessment in both mathematics and reading, in which we are able to both look at student growth in comparison to a normed group of student data from the United States, as well as comparing our students' progress at each grade level compared to other international students.  The normed group of data was gathered in the U.S.A. prior to the pandemic, and international student comparison data is not yet available for either this spring’s testing or 2020 – 2021 growth data.  We will report during an October Parent Education Tuesday event the data showing PSI student data in comparison to other international school students.  When considering student growth, we see that in mathematics, 37.7% and in reading 50.6% of our students met pre-pandemic levels of expected growth.  With our students already performing strongly, when we compare our students to the pre-pandemic spring data out of the United States, we see that 97.5% of our students tested either at or above grade level in mathematics, and 91.4% of students performed at or above grade level in reading during the spring testing.`}</p>
    <p>{`Our students in Kindergarten through Grade 5 participate in the DRA2 in the fall and spring. It is administered individually to each student to understand each child’s reading capabilities. It is a tool to be used by teachers to identify a student’s reading level, accuracy, fluency, and comprehension. Teachers use this information for planning instruction.   All students demonstrated growth between the fall and spring, and over 80% of our students who are not in the EAL programme were assessed as reading above grade level in the spring.`}</p>
    <p>{`All of our students in Kindergarten through Grade 5 are also individually assessed with either JAM or GloSS assessment in the fall and spring.  These are assessments of a conceptual understanding of students’ numeracy abilities by considering what stage they are operating with different strategies in the different strategy domains: additive, multiplicative and proportional.  Our youngest students are assessed with the JAM and once their mathematical abilities in one or more domains are high enough, they transition to the GloSS.  We piloted the GloSS and JAM last year, and this was our first year assessing all students with these tools.  Like the DRA2, these assessments provide teachers with additional understanding of individual student’s understanding in order to inform instruction.  Unlike DRA2, the JAM and GloSS provide a deeper understanding of the conceptual strategies students use rather than assessing if students are in comparison to grade level.  On these assessments, all our students demonstrated growth by using more advanced mathematical strategies in the spring than in the fall.`}</p>
    <Chart mdxType="Chart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      